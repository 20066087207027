
import React, { useState, useRef,useEffect } from 'react';
import Chatbotwidget from './Chatbotwidget';

import bg from './Assets/logo-go-Blanco (1).png'


function App() {
  const getCookie = (name) => {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
        const [cookieName, cookieValue] = cookie.trim().split('=');
        if (cookieName === name) {
          return cookieValue;
        }
      }
      return null;
    };
  
  
    const [isChatboxOpen, setChatboxOpen] = useState(false);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [sessionId,setSessionId]=useState()

    
   
  

    
  
  
  
    const [messages,setMessages]=useState([])
    const inputRef = useRef(null);
    const [waiting,setWaiting]=useState(false)

  
  



  
  const setCookie = (name, value, days) => {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + days * 24 * 60 * 60 * 1000);
  
    const expires = `expires=${expirationDate.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expires}; path=/`;
  };
  

  
  
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    window.addEventListener('resize', handleResize);
  

   
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [sessionId]);
  
  
    const messagesRef = useRef(null);
  
    // Function to scroll to the bottom of the messages
    const scrollToBottom = () => {
      if (messagesRef.current) {
        messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
      }
    };
  
    const handleButtonClick = () => {
      setChatboxOpen(!isChatboxOpen);
    };
  
  
        const apiCall = async (userMessage) => {

          const storedMessageHistory = JSON.parse(localStorage.getItem('chatbot_message_history'));

        try {
    


   

          const response = await fetch('https://robertprojectapi.onrender.com/api/message/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
              message: userMessage,              
              message_history: storedMessageHistory || null,
            }),
          });
  
          if (response.ok) {
            const data = await response.json();
            console.log(data)

  
  

            if (data.message_history) {
              console.log(data.message_history)
              console.log(JSON.stringify(data.message_history))
              localStorage.setItem("chatbot_message_history", JSON.stringify(data.message_history));
              setMessages((prevMessages) => [
                  ...prevMessages,
                  { role: 'assistant', content: data.reply, isBusiness: false },
              ]);
          }
          

        
           
            setWaiting(false)
  
            scrollToBottom()

          } else {
           
            console.error('Failed to fetch data from the API');
            setWaiting(false)
            setMessages((prevMessages) => [
              ...prevMessages,
              { role: 'assistant',content: "An Error Occured Try again later",isBusiness:false},
            ]);
          }
        } catch (error) {
          console.error('Error occurred while fetching data:', error);
          setWaiting(false)
          setMessages((prevMessages) => [
            ...prevMessages,
            { role: 'assistant',content: `An Error Occured Try again later`,isBusiness:false},
          ]);
          // apiCall(userMessage)
        }
  
        // Clear the input field
  
      }
  
  
  
   

 
  
    const handleKeyPress = async (e) => {
  
      if (e.key === 'Enter') {
  
        const userMessage = e.target.value;

  
        e.target.value = '';
  


        setMessages((prevMessages) => [
          ...prevMessages,
          { role: 'user', content: userMessage,isBusiness:true },
        ]);

        scrollToBottom()
        apiCall(userMessage)

        
  
  
    }

    
  
  
    };


    const handlesendMessage = async (e) => {
  
      const userMessage = inputRef.current.value;
      inputRef.current.value = '';



        setMessages((prevMessages) => [
          ...prevMessages,
          { role: 'user', content: userMessage,isBusiness:true },
        ]);

        scrollToBottom()
        apiCall(userMessage)

        
    };



  
  return (
    
    <div
      style={{
        position: 'fixed',
        bottom: 10,
        right: 10,
        zIndex: 999,
      }}
    >
      <div>

      </div>
      <button
        style={{
          borderRadius: 30,
          background: '#002655',
          padding: 10,
          transition: 'box-shadow 0.3s ease',
          boxShadow: '0 0 0 0 #002655',
        }}
        onClick={handleButtonClick}
        onMouseOver={(e) => (e.currentTarget.style.boxShadow = '0 0 10px 5px #002655')}
        onMouseOut={(e) => (e.currentTarget.style.boxShadow = '0 0 0 0 #002655')}
      >
        {isChatboxOpen ? (
          <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M18.601 8.39897C18.269 8.06702 17.7309 8.06702 17.3989 8.39897L12 13.7979L6.60099 8.39897C6.26904 8.06702 5.73086 8.06702 5.39891 8.39897C5.06696 8.73091 5.06696 9.2691 5.39891 9.60105L11.3989 15.601C11.7309 15.933 12.269 15.933 12.601 15.601L18.601 9.60105C18.9329 9.2691 18.9329 8.73091 18.601 8.39897Z" fill="white"></path>
          </svg>
        ) : (
          <img
            src='https://static.ada.support/images/e1f9c0f5-51ce-45e4-8240-41d357ef3ed8.svg'
            alt='Chatbot'
          />
        )}
      </button>
  
      {isChatboxOpen && (
        <div>
      
            <div
            style={{
              position: 'fixed',
              bottom: windowWidth < 768 ? '20%': '10%',
              // top:windowWidth < 768 ? '1%' : '5%',
              right: windowWidth < 768 ? 0 : 10, // Adjust the breakpoint as needed
              // right: 10,
              background: 'white',
              paddingInline: 10,
              paddingTop: 10,
              height:  windowWidth < '50%' ? 0 : '80%',
              borderRadius: 10,
              border:'1px solid #EDE2F5',
              width: windowWidth < 768 ? '100%' : '25%', // Adjust the breakpoint as needed
              zIndex:999,
  
            }}
          >
            <div
              style={{
                background: '#ff221d',
                width: '100%',
                height: '10%',
                borderRadius: 10,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: 10,
                justifyContent: 'space-between',
                border: '1px solid #e1e1e5',
              }}
            >
              <div>
              {waiting?(
        <div style={{marginTop:10,marginBottom:10,justifyContent:'center',alignItems:'center',display:'flex'}}>
       
        <span className="typing-indicator-dot"></span>
        <span className="typing-indicator-dot"></span>
        <span className="typing-indicator-dot"></span>
    </div>
  
          ):(
              <div style={{display:'flex',alignItems:'center',gap:20}}>
                <div>
                <img
      style={{
        // border: '2px solid #ffff',
        width:40,
        height:30,
        borderRadius:'60%' ,
        backgroundColor:'transparent'
         // 2px width, solid border with purple color
      }}
      src="https://pbs.twimg.com/media/GI5xJm4XIAAAUrg?format=png&name=small"
      alt="Logo"
    />
                  </div>
                  
    <div>
      <h1 style={{color:'white',fontWeight:'bold'}}>BIENVENIDOS A GOCHAT</h1>
    </div>
              </div>
          )}
    
          
              </div>
      
            </div>
              
  
            <div
    style={{
      height: '80%',
      overflowY: 'scroll',
      padding: 10,
    }}
    ref={messagesRef}
  >
<div style={{
  display: 'flex',
  flexDirection: 'row',
  gap:10,
  width: '100%',
  // justifyContent:'center',
  padding:10,
  // height: '30%',
  height:'auto',
  overflowX: 'scroll',
  marginBottom: 20,
}}>


</div>

      {messages.map((message, index) => (
          <div key={index} style={{ marginBottom: 10 }}>
          {message.isBusiness  ? (
              <div style={{
              padding: 8,
              borderRadius: 5,
              background: '#002655',
              color: 'white',
              }}>
              <p>{message.content}</p>
              </div>
          ) : (

              <div style={{
              padding: 8,
              borderRadius: 5,
              background: '#04a0ff',
              color: 'white',
              }}>
              <p>{message.content}</p>
  
              
          
              </div>
  
          )}
          </div>
      ))}
      </div>
            <div style={{display:'flex',alignItems:'center',width:'100%'}}>
              <div style={{width:'100%'}}>
              <input
                ref={inputRef}
                type='text'
                style={{
                  width: '100%',
                  border: '1px solid #e1e1e5',
                  background: '#f3f3f3',
                  // marginTop: 20,
                  padding: 10,
                  color: '#444',
                  borderRadius: 15,
                  fontSize:16,
                  lineHeight:2
                  
                }}
                placeholder='Send Message ...'
               onKeyDown={handleKeyPress}
               disabled={waiting}
              />

                </div>
                <div style={{width:'5%',marginLeft:'-30px'}}>
                  <button onClick={handlesendMessage} disabled={waiting} > 
                  <svg   xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="_svg_1cum9_1 "><path d="M19.5656 12.5913C19.4092 12.9268 19.1426 13.1929 18.8233 13.3529L6.75036 19.4461C5.89387 19.8649 4.86032 19.5233 4.42563 18.6844C4.2343 18.2793 4.19549 17.822 4.32795 17.3955L5.64831 13.0972C5.7692 12.7037 6.13274 12.435 6.54448 12.435H11.1654C11.505 12.4337 11.7828 12.1592 11.7905 11.8101C11.7884 11.4663 11.514 11.1885 11.1654 11.1851H6.54788C6.13452 11.1851 5.76995 10.9144 5.65042 10.5187L4.34749 6.20514C4.07489 5.3055 4.58948 4.34594 5.50008 4.07643C5.92157 3.94393 6.37753 3.98415 6.76989 4.19362L18.8233 10.2868C19.6653 10.7168 20.0041 11.7517 19.5656 12.5913Z" fill="#002655"></path></svg>
                  </button>
                </div>

            </div>
          </div>
  
  
     
        </div>
      )}
    </div>
  );
   
  };
export default App;
